<template>
  <v-row>
    <v-col cols="12" v-if="params.column.colId == 'created'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{ formatLastUpdatedTime(params.data.created) }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getDate }}
        </span>
      </v-tooltip>
      <br />
      <!-- <span class="mr-1">
        {{ getDate }}
      </span> -->
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'modified'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{ formatLastUpdatedTime(params.data.modified) }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getModifiedDate }}
        </span>
      </v-tooltip>
      <br />
      <!-- <span class="mr-1">
        {{ getModifiedDate }}
      </span>
      <br /> -->
    </v-col>
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  data() {
    return {
      errors: [],
      nonFieldErrors: "",
    };
  },
  computed: {
    getDate() {
      return convertDateTime(this.params.data.created);
    },
    getModifiedDate() {
      return convertDateTime(this.params.data.modified);
    },
    getLastUpdatedDate() {
      return this.params.data.last_updated
        ? convertDateTime(this.params.data.last_updated)
        : null;
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
    lastUpdated() {
      formatLastUpdated(this.params.data.updated);
    },
  },
  mounted() {},
};
</script>
