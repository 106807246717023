import { orderUpdates } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrderUpdatesData(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(orderUpdates.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderUpdateDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orderUpdates.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
