<template>
  <v-row>
    <!-- -------------created----------------- -->
    <v-col
      cols="12"
      v-if="
        params.column.colId == 'created' ||
        params.column.colId == 'updated' ||
        params.column.colId == 'modified'
      "
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{ formatLastUpdatedTime(params.data.created) }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getDate }}
        </span>
      </v-tooltip>
    </v-col>

    <!-- -------------is_webhook_enabled----------------- -->
    <v-col v-if="params.column.colId == 'is_webhook_enabled'">
      <v-icon v-if="params.data.is_webhook_enabled" color="green">
        mdi-checkbox-marked-circle
      </v-icon>
      <v-icon color="red" v-else> mdi-close-circle </v-icon>
    </v-col>

    <!-- -------------api_key----------------- -->
    <v-col cols="12" class="ellipsis" v-if="params.column.colId == 'api_key'">
      <v-icon small v-if="params.data.api_key" @click="copyApiKey()">
        mdi-content-copy
      </v-icon>
      {{ params.data.api_key }}
    </v-col>

    <!-- -------------has_resend----------------- -->
    <v-col
      cols="12"
      class="ellipsis"
      v-if="params.column.colId == 'has_resend'"
    >
      <v-icon v-if="params.data.has_resend == 'No'" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <!-- -------------is_success----------------- -->
    <v-col v-if="params.column.colId == 'is_success'">
      <v-tooltip
        content-class="custom-tooltip"
        color="#E5E5E5"
        bottom
        v-if="params.data.is_success == 'No' && params.data.response !== null"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="text-white text-body-2 mr-1 font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="red">mdi-close-circle</v-icon>
          </span>
        </template>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-list
              dense
              style="background: inherit !important"
              v-for="(error, i) in errors"
              :key="i"
            >
              <v-list-item dense style="min-height: 10px !important">
                <span>{{ error }}</span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tooltip>
      <v-icon
        color="red"
        v-else-if="
          params.data.is_success == 'No' || params.data.has_updated == 'No'
        "
      >
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <!-- has_api -->
    <v-col cols="12" class="ellipsis" v-if="params.column.colId == 'has_api'">
      <v-icon v-if="params.data.has_api" color="green">
        mdi-checkbox-marked-circle
      </v-icon>
      <v-icon v-else color="red">mdi-close-circle </v-icon>
    </v-col>

    <!-- trace_id -->
    <v-col cols="12" v-if="params.column.colId == 'trace_id'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1 ellipsis" v-bind="attrs" v-on="on">
            {{ params.data.trace_id }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ params.data.trace_id }}
        </span>
      </v-tooltip>
    </v-col>

    <!-- from_integration -->
    <v-col
      cols="12"
      class="ellipsis"
      v-if="params.column.colId == 'from_integration'"
    >
      <v-icon v-if="params.data.from_integration" color="green">
        mdi-checkbox-marked-circle
      </v-icon>
      <v-icon v-else color="red">mdi-close-circle </v-icon>
    </v-col>

    <!-- status_code -->
    <v-col v-if="params.column.colId == 'status_code'">
      <v-chip
        :color="
          params.data.status_code == 200 || params.data.status_code == 201
            ? 'green'
            : 'red'
        "
        size="x-small"
        class="text-white font-weight-bold"
      >
        <span>
          {{ params.data.status_code ? params.data.status_code : "-" }}
        </span>
      </v-chip>
    </v-col>

    <!-- is_synced -->
    <v-col v-if="params.column.colId == 'is_synced'">
      <v-icon v-if="params.data.is_synced" color="green">
        mdi-checkbox-marked-circle
      </v-icon>
      <v-icon color="red" v-else> mdi-close-circle </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    checkResponse() {
      return this.params.data.is_success == "No" && this.params.data.response;
    },
    getDate() {
      let value = this.params.data.created
        ? this.params.data.created
        : this.params.data.updated
        ? this.params.data.updated
        : this.params.data.modified;
      return convertDateTime(value);
    },
  },
  mounted() {
    if (
      this.checkResponse &&
      this.params.data.response.error &&
      typeof this.params.data.response.error == "object" &&
      Object.keys(this.params.data.response.error).length
    ) {
      this.errors = this.params.data.response.error;
    } else if (this.checkResponse && this.params.data.response.message) {
      this.errors = this.params.data.response;
    } else if (this.checkResponse && this.params.data.status_code == 500) {
      this.errors = ["Connection error"];
    }
  },
  methods: {
    copyApiKey() {
      this.params.context.parentComponent.copyApiKey(this.params.data.api_key);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
  },
};
</script>
<style scoped>
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
