<template>
  <v-dialog
    v-model="showClientWebhookLogsDialog"
    persistent
    width="65%"
    max-width="100vw"
  >
    <v-card rounded="lg" elevation="2">
      <v-card-title class="primary">
        <v-row class="d-flex justify-space-between">
          <v-col cols="10">
            <h3 class="text-body-1 text-white font-weight-bold">
              Webhook Logs
            </h3>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon color="white" @click="showClientWebhookLogsDialog = false">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3">
        <BaseListLayout
          name="webhookLogsList"
          ref="webhookLogsList"
          :table-header="clientWebhookLogColumns"
          :table-data="clientWebhookLogsData"
          :context="context"
          :total="totalItems"
          :gridStyle="gridStyle"
          :showColumnSelection="false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";

export default {
  name: "ClientWebhookLogsDialog",
  components: {
    BaseListLayout,
    CellRenderer,
  },
  props: {
    value: {
      type: Boolean,
    },
    rowData: {
      required: true,
    },
  },
  data() {
    return {
      totalItems: 0,
      gridStyle: {
        width: "100%",
        height: "60vh",
      },

      clientWebhookLogsData: [],
      clientWebhookLogColumns: [
        { headerName: "AWB Number", field: "awb_number" },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Status", field: "status" },
        // { headerName: "Endpoint", field: "endpoint" },
        {
          headerName: "Response Code",
          field: "status_code",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
      ],
    };
  },
  watch: {
    showClientWebhookLogsDialog(value) {
      if (value) {
        this.getClientWebhookLogsList();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    showClientWebhookLogsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getClientWebhookLogsList(params = {}) {
      bus.$emit("showLoader", true);
      params.awb = this.rowData.id;
      params.is_success = true;
      params.limit = "all";

      this.$api.outboundLogs
        .getClientWebhookLogsList({ ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.clientWebhookLogsData = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
};
</script>

<style></style>
