import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editDetails()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"medium":"","color":"green"}},[_vm._v("mdi-pencil")])],1)]}}])},[_vm._v(" Update Details ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewDetails()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary","medium":""}},[_vm._v("mdi-eye")])],1)]}}])},[_vm._v(" View Details ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openOrganizationApiPage()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"black","large":""}},[_vm._v(" mdi-api ")])],1)]}}])},[_c('span',[_vm._v(" View API")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openOrganizationVendorPage()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"medium":"","color":"primary"}},[_vm._v("mdi-alpha-v-circle")])],1)]}}])},[_c('span',[_vm._v("View Vendors")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyOrganizationApiKey()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"medium":"","color":"primary"}},[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Copy API Key")]),_c('br')])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }