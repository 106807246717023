<template>
  <div class="custom-list">
    <v-row v-if="title || hasAddButton" no-gutters class="ma-0">
      <v-col cols="12">
        <v-card
          elevation="0"
          style="background-color: #f5f8fa"
          class="pa-1 list-box-Shadow"
        >
          <v-row no-gutters>
            <v-col cols="4" class="text-left d-flex align-center" v-if="title">
              <v-icon v-if="showBackIcon" class="pr-2" @click="$router.back()">
                mdi-arrow-left
              </v-icon>
              <h3 class="text-uppercase text--primary">
                {{ title }}
              </h3>
            </v-col>
            <v-col :cols="title ? '8' : '12'" class="d-flex justify-end">
              <slot name="addButton" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="hasTabs" class="pt-1">
      <v-col cols="12">
        <slot name="tabSlot" />
      </v-col>
    </v-row>

    <v-row
      v-if="searchable || showColumnSelection"
      no-gutters
      class="white pa-2 rounded-lg mx-1"
    >
      <v-col cols="8">
        <v-row>
          <v-col cols="12" lg="3" class="app-search-input">
            <v-text-field
              v-if="searchable"
              outlined
              class="rounded-pill"
              v-model.trim="search"
              prepend-inner-icon="mdi-magnify"
              dense
              label="Search"
              clearable
              hide-details="auto"
              @keydown.enter.prevent="searchEnterdValues(search)"
              @click:clear="clearSearch"
            />
          </v-col>
          <v-col cols="12" lg="9" class="d-flex">
            <slot name="leftFilterSlot" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-end">
        <slot name="rightSlot"></slot>
        <Columns
          v-if="showColumnSelection"
          :headers="tableHeader"
          :gridColInstance="gridColumnApi"
          :localStorageKey="localStorageKey"
        />
        <v-badge
          v-show="isState"
          class="ml-2 text-primary table-chip-index"
          top
          overlap
          color="green"
          style="z-index: 2 !important"
        >
          <v-btn
            small
            class="ma-1"
            depressed
            color="primary"
            @click="clearState"
          >
            <v-icon color="white" size="20px"> mdi-table-column-width </v-icon>
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>
    <v-row class="white pa-2 rounded-lg mx-1">
      <v-col cols="12">
        <AgGridVue
          :rowHeight="rowHeight"
          :style="gridStyle"
          class="ag-theme-alpine"
          :columnDefs="tableHeader"
          :default-col-def="defaultColDef"
          :context="{ ...context, ...listParentComponentContext }"
          :grid-options="gridOptions"
          :rowData="tableData"
          v-on="$listeners"
          @selection-changed="onSelectionChanged"
          @grid-ready="onGridReady"
          :enableBrowserTooltips="true"
        >
        </AgGridVue>
      </v-col>
      <v-col v-if="total > 0" cols="12" class="py-0 pt-0">
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-start">
            <v-chip label style="background-color: #112f4f">
              <span class="text-body-2 text-uppercase white--text">
                Total:
              </span>
              <span class="mx-1 font-weight-bold white--text">
                {{ total }}
              </span>
            </v-chip>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <BasePagination
              :pageNo="pageNo"
              :totalItems="total"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <slot name="dialogs" />
    <slot name="detail" />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import { getAllUserPreferences, getUserPreferences } from "@/utils/functions";
import BasePagination from "@/components/BaseComponents/BasePagination.vue";
import Columns from "@/components/common/Columns.vue";

export default {
  emits: ["localStorageKey"],
  components: {
    AgGridVue,
    BasePagination,
    Columns,
  },
  props: {
    name: {
      required: true,
    },
    title: {
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableHeader: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      default: () => {},
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    hasTabs: {
      default: false,
    },
    gridStyle: {
      type: Object,
      default() {
        return {
          width: "100%",
          height: "calc(100vh - 335px)",
        };
      },
    },
    hasAddButton: {
      type: Boolean,
      default: false,
    },
    showColumnSelection: {
      default: true,
    },
    localStorageKey: {
      required: false,
    },
    rowHeight: {
      default: 40,
    },
    showBackIcon: {
      default: false,
    },
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: this.$globalConstant.itemsPerPage,
      pageNo: 1,
      filters: {},
      defaultColDef: {
        lockPosition: false,
        sorting: true,
        resizable: true,
        flex: 1,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          if (this.previousState && this.previousState.length === 0) {
            setTimeout(() => {
              this.gridOptions.api.sizeColumnsToFit();
            }, 100);
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        isRowSelectable: false,
        enableCellTextSelection: true,
        alwaysShowHorizontalScroll: true,
        onColumnResized: this.onColumnChanged,
        onColumnMoved: this.onColumnChanged,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      search: null,
      selectedRows: [],
      timeInterval: null,
      paginationTimeout: null,
      timeOut: 0,
      isState: false,

      // persistant pagination variable
      changedPaginationValue: "",
    };
  },
  watch: {
    tableHeader: {
      handler: function (val, oldVal) {
        let nVal = val.map((v) => v.field);
        let oVal = oldVal.map((v) => v.field);
        let isChanged = !nVal
          .map((v, index) => v && oVal && oVal[index] === nVal[index])
          .every((v) => v === true);

        if (isChanged) {
          this.checkingState();
          this.getUpdateState();
        }
      },
      deep: true,
    },
  },
  computed: {
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    listParentComponentContext() {
      return { listParentComponentContext: this };
    },
    previousState() {
      return this.localStorageKey
        ? getUserPreferences(this.localStorageKey)
        : null;
    },
  },
  methods: {
    checkingState() {
      let preState = getUserPreferences(this.localStorageKey);
      this.isState =
        this.localStorageKey && preState && preState.length > 0 ? true : false;
    },
    getUpdateState() {
      if (this.gridColumnApi && this.localStorageKey) {
        setTimeout(() => {
          this.gridColumnApi.applyColumnState({
            state: getUserPreferences(this.localStorageKey),
          });
        }, 150);
      }
    },
    clearState() {
      if (this.localStorageKey && this.gridColumnApi) {
        let allColumnState = getAllUserPreferences();
        allColumnState[this.localStorageKey] = [];
        localStorage.setItem("userPreferences", JSON.stringify(allColumnState));
        this.resizeGrid();
        this.gridColumnApi.resetColumnState();
        this.checkingState();
      }
    },
    onColumnChanged(params) {
      if (this.timeoutFn) {
        clearTimeout(this.timeoutFn);
      }
      this.timeoutFn = setTimeout(() => {
        if (
          this.localStorageKey &&
          params &&
          (params.source === "uiColumnResized" ||
            params.source === "uiColumnMoved")
        ) {
          let allColumnState = getAllUserPreferences();
          allColumnState[this.localStorageKey] =
            this.gridColumnApi.getColumnState();
          localStorage.setItem(
            "userPreferences",
            JSON.stringify(allColumnState)
          );
          this.checkingState();
        }
      }, 50);
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    clearSearch() {
      this.search = null;
      this.itemsPerPageChanged(this.itemsPerPage);
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.$emit("selectionChanged", this.selectedRows);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchEnterdValues(val) {
      if (val == "") {
        this.clearSearch();
      } else {
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
          this.itemsPerPageChanged(this.itemsPerPage);
        }, 500);
      }
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      if (this.search) {
        this.filters.search = this.search.trim();
      } else {
        delete this.filters.search;
      }

      if (this.itemsPerPage) {
        localStorage.setItem(this.changedPaginationValue, this.itemsPerPage);
      }

      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },
    prevPage() {
      this.pageNo--;
      if (this.paginationTimeout) {
        clearTimeout(this.paginationTimeout);
      }
      this.paginationTimeout = setTimeout(() => {
        this.$emit("getList", {
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
        });
      }, 100);
    },
    nextPage() {
      this.pageNo++;
      if (this.paginationTimeout) {
        clearTimeout(this.paginationTimeout);
      }
      this.paginationTimeout = setTimeout(() => {
        this.$emit("getList", {
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
        });
      }, 100);
    },
    refreshList() {
      this.pageNo = 1;
      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },
    updateList() {
      this.$emit("getList", {
        ...this.filters,
        offset: this.offset,
        limit: this.itemsPerPage,
      });
    },

    setPersistantPagination() {
      if (
        typeof window !== "undefined" &&
        localStorage &&
        localStorage.getItem(this.changedPaginationValue)
      ) {
        this.itemsPerPage = JSON.parse(
          localStorage.getItem(this.changedPaginationValue)
        );
      } else {
        localStorage.removeItem(this.changedPaginationValue);
        this.itemsPerPage = this.$globalConstant.itemsPerPage;
      }
    },
  },
  mounted() {
    this.changedPaginationValue = this.title + "Pagination";

    this.checkingState();
    setTimeout(() => {
      this.getUpdateState();
    }, 50);

    this.setPersistantPagination();

    this.$emit("getList", {
      ...this.filters,
      offset: this.offset,
      limit: this.itemsPerPage,
    });
  },
  beforeDestroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  },
};
</script>
<style>
.ag-row-odd {
  background-color: #f5f8fa;
}
.ag-theme-material .ag-header {
  border-radius: 20px;
}
.ag-header-cell {
  color: white;
  background-color: #17b7d0;
  /* background-color: #112f4f; */
}
/* .ag-theme-material .ag-row {
  border-radius: 20px;
  background-color: #f5f8fa;
} */
.ag-header-group-cell:not(.ag-column-resizing)
  + .ag-header-group-cell:not(.ag-column-hover):not(
    .ag-header-cell-moving
  ):hover,
.ag-header-group-cell:not(.ag-column-resizing)
  + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
.ag-header-cell:not(.ag-column-resizing)
  + .ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-header-cell:not(.ag-column-resizing)
  + .ag-header-cell:not(.ag-column-hover).ag-column-resizing,
.ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
.ag-header-group-cell:first-of-type.ag-column-resizing,
.ag-header-cell:not(.ag-column-hover):first-of-type:not(
    .ag-header-cell-moving
  ):hover,
.ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
  background-color: lightblue;
}
</style>
