<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="OrganizationApiList"
        ref="orgApiList"
        title="Api"
        searchable
        :table-header="columns"
        :table-data="organizationApiList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="organizationUrlColumns"
        :showBackIcon="true"
        @getList="getOrganizationApiList"
      >
        <template #addButton>
          <span
            class="pr-10 text--primary text-sm-body-2"
            v-if="$route.params.name"
          >
            Organization : {{ $route.params.name }}
          </span>
          <v-btn
            v-if="!isUserTypeOrganization"
            color="primary"
            small
            depressed
            @click="addNewOrganizationApi"
          >
            Add Api
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <BaseSelect
            style="width: 200px"
            class="pl-2 rounded-pill"
            label="Request For"
            :isClearable="true"
            item-text="display_value"
            item-value="value"
            :itemsList="requestForList"
            v-model="filters.request_for"
            @change="applyFilter"
          />
        </template>
        <template #dialogs>
          <OrganizationApiForm
            ref="organizationApiForm"
            v-model="showOrganizationApiForm"
            :organizationApiID="organizationApiID"
            :editMode="editMode"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
    <OrganizationApiDetails
      v-model="showOrganizationURLDetails"
      :organizationApiDetails="organizationApiDetails"
      @closeDetails="showOrganizationURLDetails = false"
    />
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import OrganizationApiForm from "./OrganizationApiForm.vue";
import OrganizationApiDetails from "./OrganizationApiDetails.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions";

export default {
  name: "OrganizationApiList",
  components: {
    BaseListLayout,
    ActionsButton,
    OrganizationApiForm,
    OrganizationApiDetails,
    BaseSelect,
  },
  data() {
    return {
      filters: {},
      requestForList: [],
      canEdit: true,
      organizationApiList: [],
      organizationApiDetails: {},
      organizationApiID: null,
      totalItems: 0,
      editMode: false,
      showOrganizationApiForm: false,
      showOrganizationURLDetails: false,
      fromOrganizationApi: true,
      columns: [
        {
          headerName: "Api",
          field: "url",
          sortable: true,
        },
        {
          headerName: "Request For",
          field: "request_for",
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    addNewOrganizationApi() {
      this.editMode = false;
      this.showOrganizationApiForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.organizationApiID = id;
      this.showOrganizationApiForm = true;
    },
    viewDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.organizationUrl
        .viewOrganizationURLObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.organizationApiDetails = res.data;
          this.showOrganizationURLDetails = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    getOrganizationApiList(params) {
      bus.$emit("showLoader", true);
      params = {
        organization: this.$route.params.id,
        ...params,
      };
      let filters = localStorage.getItem("Org_Api_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("request_for" in filters) {
        filters.request_for = filters.request_for;
      }

      this.$api.organizationUrl
        .getOrganizationURLList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.organizationApiList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.orgApiList.refreshList();
    },
    updateList() {
      this.$refs.orgApiList.updateList();
    },
    getRequestForOptions() {
      this.$api.organizationUrl
        .getRequestForOptions({ request_type: "form" })
        .then((res) => {
          this.requestForList = res?.data?.request_for;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilter() {
      localStorage.setItem("Org_Api_Filters", JSON.stringify(this.filters));
      this.$refs.orgApiList.refreshList();
    },
  },
  mounted() {
    this.getRequestForOptions();
    let f = localStorage.getItem("Org_Api_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>
