<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="viewDetails()"
            :disabled="!(params.data.awb && params.data.id)"
          >
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
        </template>
        View
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(
        this.params.data.id,
        this.params.data.awb
      );
    },
  },
};
</script>

<style></style>
