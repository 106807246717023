<template>
  <div>
    <v-row class="mt-0 primary d-flex align-center fixed_header">
      <v-col cols="10">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-white text-body-2 mr-1 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              {{ detailsObject.awb_number }}
            </span>
          </template>
          AWB Number
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-white text-body-2 mr-1 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              ({{ detailsObject.status }})
            </span>
          </template>
          Status
        </v-tooltip>
      </v-col>
      <v-col cols class="d-flex align-center justify-end">
        <v-btn small depressed @click="$emit('closeDetails')" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <!-- Basic Details -->
      <v-col cols="12" class="mt-5">
        <v-card elevation="0" outlined class="rounded-lg">
          <v-card-title
            class="py-1 px-3 ma-0 secondary text-white font-weight-bold subtitle-1"
          >
            Shipment Information
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            class="px-3 py-2 overflow-y-auto"
            style="max-height: calc(40vh)"
          >
            <v-row
              no-gutters
              class="mt-1"
              v-for="(detail, key) in basicDetails"
              :key="key"
            >
              <v-col cols="4" class="text-capitalize">
                {{ detail.label }}
              </v-col>
              <v-col cols="8" class="text-right">
                <span class="primary--text font-weight-bold">
                  {{ detail.value ? detail.value : "-" }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Shipper Information -->
      <v-col cols="12" class="mt-2">
        <v-card elevation="1" class="rounded-lg">
          <v-card-title
            class="py-1 px-3 ma-0 secondary text-white font-weight-bold subtitle-1"
          >
            Shipper Address
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-3 py-2">
            <v-row
              no-gutters
              class="mt-1"
              v-for="(detail, key) in shipperDetails"
              :key="key"
            >
              <v-col cols="3" class="text-capitalize">
                {{ detail.label }}
              </v-col>
              <v-col cols="9" class="text-right">
                <span class="primary--text font-weight-bold">
                  {{ detail.value ? detail.value : "-" }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Consignee Information -->
      <v-col cols="12" class="mt-2">
        <v-card elevation="1" class="rounded-lg">
          <v-card-title
            class="py-1 px-3 ma-0 secondary text-white font-weight-bold subtitle-1"
          >
            Consignee Address
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-3 py-2">
            <v-row
              no-gutters
              class="mt-1"
              v-for="(detail, key) in consigneeDetails"
              :key="key"
            >
              <v-col cols="3" class="text-capitalize">
                {{ detail.label }}
              </v-col>
              <v-col cols="9" class="text-right">
                <span class="primary--text font-weight-bold">
                  {{ detail.value ? detail.value : "-" }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "OrderUpdateDetails",
  props: {
    detailsObject: { type: Object, default: () => {} },
  },
  computed: {
    basicDetails() {
      return [
        {
          label: "Product Code",
          value: this.detailsObject.prod_code,
        },
        {
          label: "Organization",
          value: this.detailsObject.organization,
        },
        {
          label: "Customer Reference No",
          value: this.detailsObject.customer_ref_no,
        },
        {
          label: "Account Number",
          value: this.detailsObject.account_number,
        },
        {
          label: "Vendor",
          value: this.detailsObject.vendor,
        },
        {
          label: "Vendor AWB Number",
          value: this.detailsObject.vendor_awb_no,
        },
        {
          label: "Origin",
          value: this.detailsObject.origin,
        },
        {
          label: "Destination",
          value: this.detailsObject.destination,
        },
        {
          label: "Date",
          value: this.detailsObject.date,
        },
        {
          label: "Instruction",
          value: this.detailsObject.instruction,
        },
        {
          label: "Weight",
          value: this.detailsObject.weight,
        },
        {
          label: "Pieces",
          value: this.detailsObject.pieces,
        },
        {
          label: "Content",
          value: this.detailsObject.content,
        },
        {
          label: "Value",
          value: this.detailsObject.value,
        },
      ];
    },
    shipperDetails() {
      return [
        {
          label: "Shipper",
          value: this.detailsObject.shipper,
        },
        {
          label: "Name",
          value: this.detailsObject.shipper_name,
        },
        {
          label: "Address 1",
          value: this.detailsObject.shipper_add1,
        },
        {
          label: "Address 2",
          value: this.detailsObject.shipper_add2,
        },
        {
          label: "City",
          value: this.detailsObject.shipper_city,
        },
        {
          label: "Telephone Number",
          value: this.detailsObject.telephone_number,
        },
        {
          label: "Secondaray Telephone Number",
          value: this.detailsObject.telephone_number2,
        },
      ];
    },
    consigneeDetails() {
      return [
        {
          label: "Consignee",
          value: this.detailsObject.consignee,
        },
        {
          label: "Name",
          value: this.detailsObject.consignee_name,
        },
        {
          label: "Address 1",
          value: this.detailsObject.consignee_add1,
        },
        {
          label: "Address 2",
          value: this.detailsObject.consignee_add2,
        },
        {
          label: "City",
          value: this.detailsObject.city,
        },
        {
          label: "Telephone Number",
          value: this.detailsObject.consignee_telephone_number_1,
        },
        {
          label: "Secondary Telephone Number",
          value: this.detailsObject.consignee_telephone_number_2,
        },
      ];
    },
  },
};
</script>

<style scoped>
.fixed_header {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
