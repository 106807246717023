<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary" medium>mdi-eye</v-icon>
          </v-btn>
        </template>
        View Details
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="params.context.parentComponent.fromShipmentAWBList"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="fetchAndResendUpdate()">
            <v-icon color="primary" medium>mdi-reload</v-icon>
          </v-btn>
        </template>
        Fetch and Resend Update
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="params.context.parentComponent.fromShipmentAWBList"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewAWBLogs()">
            <span
              class="text-h6 text--primary rounded-lg text-caption font-weight-bold"
            >
              AWB
            </span>
          </v-btn>
        </template>
        AWB Logs
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="params.context.parentComponent.fromShipmentAWBList"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewWebhookLogs()">
            <v-icon color="primary">mdi-webhook</v-icon>
          </v-btn>
        </template>
        Webhook Logs
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    fetchAndResendUpdate() {
      this.params.context.parentComponent.fetchSingleShipmentUpdate(
        this.params.data.id
      );
    },
    viewAWBLogs() {
      this.params.context.parentComponent.viewAWBLogs(this.params.data.id);
    },
    viewWebhookLogs() {
      this.params.context.parentComponent.viewWebhookLogs(this.params.data);
    },
  },
};
</script>

<style></style>
