<template>
  <v-select
    :value="selectedDateRange"
    hide-details="auto"
    outlined
    dense
    :clearable="clearable"
    :items="presetDates"
    label="Select Date Range"
    item-text="label"
    item-value="value"
    @change="onRangeChange"
  ></v-select>
</template>

<script>
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subMonths,
  subYears,
  subDays,
} from "date-fns";

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedDateRange: {
      get() {
        return [
          this.modelValue?.start_date || "",
          this.modelValue?.end_date || "",
        ];
      },
    },
  },
  data() {
    return {
      presetDates: [
        { label: "Today", value: [new Date(), new Date()] },
        {
          label: "Yesterday",
          value: [subDays(new Date(), 1), subDays(new Date(), 1)],
        },
        {
          label: "This Week",
          value: [startOfWeek(new Date()), endOfWeek(new Date())],
        },
        {
          label: "Last Week",
          value: [
            startOfWeek(subMonths(new Date(), 1)),
            endOfWeek(subMonths(new Date(), 1)),
          ],
        },
        {
          label: "This Month",
          value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          label: "Last Month",
          value: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          label: "This Year",
          value: [startOfYear(new Date()), endOfYear(new Date())],
        },
        {
          label: "Last Year",
          value: [
            startOfYear(subYears(new Date(), 1)),
            endOfYear(subYears(new Date(), 1)),
          ],
        },
      ],
    };
  },
  methods: {
    onRangeChange(selectedDatesArray) {
      const dateRange = selectedDatesArray
        ? {
            start_date: format(selectedDatesArray[0], "yyyy-MM-dd"),
            end_date: format(selectedDatesArray[1], "yyyy-MM-dd"),
          }
        : { start_date: null, end_date: null };

      this.$emit("update:modelValue", dateRange);
    },
  },
};
</script>