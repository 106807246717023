import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"65%","max-width":"100vw"},model:{value:(_vm.showSystemLogsDialog),callback:function ($$v) {_vm.showSystemLogsDialog=$$v},expression:"showSystemLogsDialog"}},[_c(VCard,{attrs:{"rounded":"lg","elevation":"2"}},[_c(VCardTitle,{staticClass:"primary"},[_c(VRow,{staticClass:"d-flex justify-space-between"},[_c(VCol,{attrs:{"cols":"10"}},[_c('h3',{staticClass:"text-body-1 text-white font-weight-bold"},[_vm._v("System Logs")])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c(VIcon,{attrs:{"color":"white"},on:{"click":function($event){_vm.showSystemLogsDialog = false}}},[_vm._v(" mdi-close ")])],1)],1)],1),_c(VCardText,{staticClass:"mt-3"},[_c('BaseListLayout',{ref:"webhookLogsList",attrs:{"name":"webhookLogsList","table-header":_vm.systemLogsColumns,"table-data":_vm.systemLogsData,"context":_vm.context,"total":_vm.totalItems,"gridStyle":_vm.gridStyle,"showColumnSelection":false}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }