<template>
  <v-row no-gutters>
    <v-col cols="9" class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col cols="4" class="pa-0 ma-0">
          <v-card class="rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Basic Information
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="detail in basicDetails"
                :key="detail.label"
                class="mt-1"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ detail.value ? detail.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3" class="ml-2">
          <v-card class="rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Origin
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="origin in originAddress"
                :key="origin.label"
                class="mt-1"
              >
                <v-col cols="4">
                  {{ origin.label }}
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ origin.value ? origin.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4" class="ml-3">
          <v-card class="rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Destination
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="destination in destinationAddress"
                :key="destination.label"
                class="mt-1"
              >
                <v-col cols="4">
                  {{ destination.label }}
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ destination.value ? destination.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-3">
        <v-col cols="4">
          <v-card class="rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Additional Information
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="detail in additionalDetails"
                :key="detail.label"
                class="mt-1"
              >
                <v-col cols="3">
                  {{ detail.label }}
                </v-col>
                <v-col cols="9" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ detail.value ? detail.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4" class="ml-3">
          <v-card class="rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Parcel Information
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="parcel in parcelInformation"
                :key="parcel.label"
                class="mt-1"
              >
                <v-col cols="4">
                  {{ parcel.label }}
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ parcel.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Events -->
    <v-col cols="3" class="ma-0 pa-0 px-3">
      <v-card class="rounded-lg" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Events
        </v-card-title>
        <v-card-text class="mt-1">
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in trackingDetailObject.ShipmentStatuses"
              :key="i"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.status }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ event.time }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.new_location }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatLastUpdated, convertDateTime } from "@/utils/functions";

export default {
  name: "SHLAwbComponent",
  components: {},
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    requestData() {
      return this.vendorDetails.request_data;
    },
    basicDetails() {
      return [
        {
          label: "Last Update Date",
          value: this.trackingDetailObject?.Shipment?.last_update_date
            ? convertDateTime(
                this.trackingDetailObject?.Shipment?.last_update_date
              ) +
              " (" +
              formatLastUpdated(
                this.trackingDetailObject?.Shipment?.last_update_date
              ) +
              ")"
            : null,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Organization At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
      ];
    },
    additionalDetails() {
      return [
        {
          label: "SKU",
          value: this.trackingDetailObject?.Shipment?.sku,
        },
        {
          label: "Pick Date",
          value:
            convertDateTime(this.trackingDetailObject?.Shipment?.pickup_date) +
            "(" +
            formatLastUpdated(
              this.trackingDetailObject?.Shipment?.pickup_date
            ) +
            ")",
        },
        {
          label: "Service Id",
          value: this.trackingDetailObject?.Shipment?.service_id,
        },
        {
          label: "Customer Branch Id",
          value: this.trackingDetailObject?.Shipment?.customer_branch_id,
        },
        {
          label: "Product Type",
          value: this.trackingDetailObject?.Shipment?.product_type,
        },
        {
          label: "Payment Mode",
          value: this.trackingDetailObject?.Shipment?.payment_mode,
        },
      ];
    },
    parcelInformation() {
      return [
        {
          label: "Parcel Quantity",
          value: this.trackingDetailObject?.Shipment?.parcel_quantity,
        },
        {
          label: "Parcel Weight",
          value: this.trackingDetailObject?.Shipment?.parcel_weight + " KG",
        },
        {
          label: "Weight Total",
          value: this.trackingDetailObject?.Shipment?.weight_total + " KG",
        },
        {
          label: "Description",
          value: this.trackingDetailObject?.Shipment?.description,
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.sender?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.sender?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.sender?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.sender?.phone,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.sender?.email,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.receiver?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.receiver?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.receiver?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone,
        },
        {
          label: "Phone2",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone2,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.receiver?.email,
        },
        {
          label: "Signature",
          value: this.trackingDetailObject?.Shipment?.receiver?.signature,
        },
        {
          label: "Photo",
          value: this.trackingDetailObject?.Shipment?.receiver?.photo,
        },
      ];
    },
  },
};
</script>
