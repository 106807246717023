<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        color="secondary"
        v-if="
          !(
            params.data.timing ||
            params.data.auth_method ||
            params.context.parentComponent.canApplyChangesToClient
          )
        "
        class="mr-1"
        x-small
        depressed
        @click="viewDetails()"
      >
        <v-icon small> mdi-eye </v-icon>
      </v-btn>
      <v-tooltip
        bottom
        v-if="params.context.fromOrganizationApi || !isUserTypeOrganization"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="
              params.data.is_success != 'Yes' &&
              params.context.parentComponent.canEdit
            "
            v-bind="attrs"
            v-on="on"
            color="secondary"
            class="ml-1"
            x-small
            depressed
            @click="editDetails()"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit Details
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.context.parentComponent.canMapStatus"
            v-bind="attrs"
            v-on="on"
            color="secondary"
            class="ml-1"
            x-small
            depressed
            @click="bulkUploadStatusSheet()"
          >
            <v-icon small>mdi-file-upload</v-icon>
          </v-btn>
        </template>
        Bulk Upload For Status Mapping
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.context.parentComponent.canViewStatusMappingDialog"
            v-bind="attrs"
            v-on="on"
            color="secondary"
            class="ml-1"
            x-small
            depressed
            @click="viewStatusMappingInfo()"
          >
            <v-icon small>mdi-information</v-icon>
          </v-btn>
        </template>
        Status Mapping Info
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.context.parentComponent.canViewOrganizationVendorLogs"
            v-bind="attrs"
            v-on="on"
            color="secondary"
            class="ml-1"
            x-small
            depressed
            @click="viewOrganizationVendorLogs()"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
        View Logs
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="secondary"
            v-if="params.context.parentComponent.canApplyChangesToClient"
            class="ml-2"
            x-small
            depressed
            @click="applyChangesToClient()"
          >
            <v-icon small>mdi-check-decagram</v-icon>
          </v-btn>
        </template>
        Apply Changes to all Client
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
import { getUserProfile } from "@/utils/functions";

export default {
  computed: {
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    applyChangesToClient() {
      this.params.context.parentComponent.applyChangesToClient(
        this.params.data.id
      );
    },
    bulkUploadStatusSheet() {
      this.params.context.parentComponent.bulkUploadStatusSheet(
        this.params.data.id
      );
    },
    viewStatusMappingInfo() {
      this.params.context.parentComponent.viewStatusMappingDialog(
        this.params.data.id
      );
    },
    viewOrganizationVendorLogs() {
      this.params.context.parentComponent.viewOrganizationVendorLogDialog(
        this.params.data.id
      );
    },
  },
};
</script>

<style></style>
