<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="shipmentAwbList"
        ref="shipmentAwbList"
        title="Client AWBs"
        :hasTabs="false"
        searchable
        :table-header="shipmentAwbColumns"
        :table-data="shipmentAwbList"
        :rowHeight="40"
        :context="context"
        :total="totalItems"
        localStorageKey="shipmentAwbListColumns"
        @getList="getShipmentAwbList"
      >
        <template #leftFilterSlot>
          <div>
            <FilterPanel
              filter_for="shipment_awb_list"
              @applyFilters="applyFilter"
            />
          </div>
        </template>
        <template #rightSlot>
          <DateRangePicker
            style="max-width: 300px"
            id="shipment-awb-list-date-range"
            v-model="dateRange"
            name="shipment-awb-list-date-range"
            @update:modelValue="updateDateRange"
          />
          <v-btn
            small
            color="primary"
            class="ma-1"
            @click="showBulkResendDialog = true"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn>
        </template>

        <template #dialogs>
          <ClientWebhookLogsDialog
            v-model="showClientWebhookLogsDialog"
            :rowData="rowData"
          />
          <Logs
            v-model="showLogsDialog"
            logTitle="AWB"
            :logHeight="
              (style = 'height:calc(71.5vh)!important; overflow-x: hidden;')
            "
            :logs="awbLogs"
          />
          <BulkResendWebhookLogsDialog
            resendUpdateFor="Client AWBS"
            v-model="showBulkResendDialog"
            @refreshList="applyFilter()"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import FilterPanel from "@/components/common/FilterPanel.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import AWBListButtons from "@/components/AgGridButtons/AWBListButtons.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import ClientWebhookLogsDialog from "./ClientWebhookLogsDialog.vue";
import Logs from "@/components/common/Logs.vue";
import BulkResendWebhookLogsDialog from "@/components/common/BulkResendWebhookLogsDialog.vue";
import { bus } from "@/main";

export default {
  name: "VendorAWBList",
  components: {
    BaseListLayout,
    BaseSelect,
    FilterPanel,
    ShipmentCellRenderer,
    AWBListButtons,
    CellRenderer,
    DateRangePicker,
    ClientWebhookLogsDialog,
    Logs,
    BulkResendWebhookLogsDialog,
  },
  data() {
    return {
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      totalItems: 0,
      filters: {},
      shipmentAwbList: [],
      shipmentAwbColumns: [
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 170,
          autoHeight: true,
          pinned: "left",
          cellClass: "text--primary font-weight-bold",
        },
        {
          headerName: "Shipper Reference Number",
          field: "shipper_reference_number",
          minWidth: 150,
        },
        {
          headerName: "Service Type",
          field: "service_type",
          minWidth: 150,
        },
        {
          headerName: "Organization",
          field: "organization_name",
          minWidth: 150,
        },
        { headerName: "Client", field: "client_name", minWidth: 150 },
        {
          headerName: "Last Status Received",
          field: "last_status_received",
          minWidth: 190,
        },
        {
          headerName: "Last Status Received At",
          field: "last_status_received_at",
          minWidth: 190,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Last Status Sent",
          field: "last_status_sent",
          minWidth: 190,
        },
        {
          headerName: "Last Status Sent At",
          field: "last_status_sent_at",
          minWidth: 190,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Created",
          field: "created",
          minWidth: 190,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 180,
          pinned: "right",
          cellRenderer: "AWBListButtons",
        },
      ],

      rowData: {},
      showClientWebhookLogsDialog: false,
      awbLogs: [],
      showLogsDialog: false,

      fromShipmentAWBList: true,
      showBulkResendDialog: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    updateDateRange(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;

      this.filters.created_after = this.dateRange.start_date;
      this.filters.created_before = this.dateRange.end_date;

      this.applyFilter(this.filters);
    },
    applyFilter(filters) {
      localStorage.setItem(
        "shipment_awb_list_filters",
        JSON.stringify(filters)
      );
      this.refreshList();
    },
    getShipmentAwbList(params = {}) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("shipment_awb_list_filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.clientAWB
        .getClientShipmentAWBList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.shipmentAwbList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting request log list",
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.shipmentAwbList.refreshList();
    },
    viewDetails(id) {
      this.$router.push({
        name: "shipment-details",
        params: { id: id },
      });
    },
    // Fetch Single Update
    fetchSingleShipmentUpdate(id) {
      this.$api.clientAWB
        .fetchUpdatesForSingleShipment(id)
        .then((res) => {
          this.refreshList();          
          bus.$emit("showToastMessage", {
            message: "Updates fetched successfully",
            color: "success",
          });
        })
        .catch((err) => {
          const error = err.data.data.error;

          bus.$emit("showToastMessage", {
            message: error.message,
            color: "red",
          });

          if (err.data.status_code == 500) {
            bus.$emit("showToastMessage", {
              message: error.error,
              color: "red",
            });
          }
        });
    },

    // Logs methods
    viewAWBLogs(id) {
      this.$api.clientAWB
        .getAwbLogsForClientShipment(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.awbLogs = res.data;
          this.showLogsDialog = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    viewWebhookLogs(row) {
      this.rowData = row;
      this.showClientWebhookLogsDialog = true;
    },
  },
  created() {
    localStorage.removeItem("shipment_awb_list_filters");
  },
};
</script>

<style></style>
