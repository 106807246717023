<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="webhookUpdateRef"
        title="Updates By AWB"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="webhookUpdateList"
        @getList="getWebhookUpdateList"
        @selectionChanged="selectionChanged"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            color="primary"
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showWebhookUpdateFilter = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #rightSlot>
          <v-btn
            :disabled="selectedItems.length == 0"
            small
            color="primary"
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn>
        </template>
        <template #dialogs>
          <WebhookUpdateFilter
            v-model="showWebhookUpdateFilter"
            @applyFilters="refreshList"
          >
          </WebhookUpdateFilter>
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>
<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import WebhookUpdateFilter from "./WebhookUpdateFilter.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "webhookUpdate",
  components: {
    BaseListLayout,
    CellRenderer,
    WebhookUpdateFilter,
    ActionButton,
    ShipmentCellRenderer,
  },
  data() {
    return {
      totalItems: 0,
      selectedItems: [],
      tableData: [],
      showWebhookUpdateFilter: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    columns() {
      return [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
          pinned: "left",
        },
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 180,
          pinned: "left",
          autoHeight: true,
          cellRenderer: "ShipmentCellRenderer",
          onCellClicked: (params) => {
            let rowId = params && params.data && params.data.id;
            if (rowId) {
              this.$router.push({
                name: "shipment-details",
                params: { id: rowId },
              });
            }
          },
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Last Status", field: "last_status" },
        {
          headerName: "Has Updated",
          field: "has_updated",
          cellRenderer: "CellRenderer",
          minWidth: 100,
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Created By", field: "created_by" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
        },
      ];
    },
  },
  methods: {
    getWebhookUpdateList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("UpdateByAWBFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.$api.webhookUpdate
        .getWebhookUpdateList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    selectionChanged(selectedRows) {
      this.selectedItems = selectedRows;
    },
    bulkResend() {
      let selected = [];
      this.selectedItems.map((item) => {
        selected.push(item.awb_number);
      });
      let params = {
        awb_numbers: selected,
      };
      this.$api.webhookUpdate
        .resendWebhookUpdate(params)
        .then((res) => {
          this.$refs.webhookUpdateRef.refreshList();
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.webhookUpdateRef.refreshList();
    },
  },
};
</script>
