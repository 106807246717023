<template>
  <v-dialog
    v-model="showSystemLogsDialog"
    persistent
    width="65%"
    max-width="100vw"
  >
    <v-card rounded="lg" elevation="2">
      <v-card-title class="primary">
        <v-row class="d-flex justify-space-between">
          <v-col cols="10">
            <h3 class="text-body-1 text-white font-weight-bold">System Logs</h3>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon color="white" @click="showSystemLogsDialog = false">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3">
        <BaseListLayout
          name="webhookLogsList"
          ref="webhookLogsList"
          :table-header="systemLogsColumns"
          :table-data="systemLogsData"
          :context="context"
          :total="totalItems"
          :gridStyle="gridStyle"
          :showColumnSelection="false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";

export default {
  name: "ClientWebhookLogsDialog",
  components: {
    BaseListLayout,
    ShipmentCellRenderer,
    ActionButton,
  },
  props: {
    value: {
      type: Boolean,
    },
    rowData: {
      required: true,
    },
  },
  data() {
    return {
      totalItems: 0,
      gridStyle: {
        width: "100%",
        height: "60vh",
      },

      systemLogsData: [],
      systemLogsColumns: [
        { headerName: "Message", field: "message" },
        { headerName: "Added By", field: "added_by", maxWidth: 200 },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
          maxWidth: 150,
        },
        { headerName: "Status Code", field: "status_code", maxWidth: 150 },
        {
          headerName: "Created",
          field: "created",
          minWidth: 180,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "More Details",
          field: "actions",
          cellRenderer: "ActionButton",
          maxWidth: 150,
        },
      ],
    };
  },
  watch: {
    showSystemLogsDialog(value) {
      if (value) {
        this.getClientShipmentSystemLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    showSystemLogsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getClientShipmentSystemLogs() {
      let params = {
        awb: this.rowData.awb,
        is_success: false,
        limit: "all",
      };

      this.$api.inbound
        .getClientShipmentSystemLogs({ ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.systemLogsData = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting system logs",
            color: "red",
          });
        });
    },
  },
};
</script>

<style></style>
