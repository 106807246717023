import { dashboard } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getDashboardStatistics(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${dashboard.base}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationDashboardStatistics(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${dashboard.orgBase}dashboard/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationDashboardCharts(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${dashboard.orgBase}dashboard-charts/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
