<template>
  <v-row>
    <v-col cols="12">
      <!----------Vendor Buttons Start------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromVendor"
        class="mr-1"
        x-small
        depressed
        @click="editVendorDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <!------------Vendor Buttons End--------------->

      <!-- -------User Buttons Start--------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromUser"
        x-small
        class="mr-1"
        depressed
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromUser"
        x-small
        depressed
        @click="changePassword()"
      >
        <v-icon small>mdi-key</v-icon>
      </v-btn>
      <!-- -------User Buttons End--------------->

      <!----------Report Buttons Start------------->
      <v-btn
        color="primary"
        v-if="
          params.context.parentComponent.fromReport &&
          params.data.total_records > 0
        "
        class="mr-1"
        x-small
        depressed
        @click="downloadReport()"
      >
        <v-icon small>mdi-download</v-icon>
      </v-btn>
      <!-- -------Report Buttons End--------------->

      <!----------Inbound Shipment Details Buttons Start------------->
      <v-btn
        text
        v-if="params.context.parentComponent.fromVendorShipmentDetails"
        @click="moreDetails()"
      >
        <v-icon color="secondary">mdi-open-in-new</v-icon>
      </v-btn>
      <!-- -------Inbound Shipment Details Buttons End--------------->

      <!----------Organization Configuration Buttons Start------------->
      <v-btn
        color="primary"
        x-small
        depressed
        v-if="params.context.parentComponent.fromOrganizationConfiguration"
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- -------Organization Configuration Buttons End--------------->
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    openOrganizationClientPage() {
      this.params.context.parentComponent.openOrganizationClientPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    downloadReport() {
      this.params.context.parentComponent.downloadReport(this.params.data);
    },
    editVendorDetails() {
      this.params.context.parentComponent.editVendorDetails(
        this.params.data.id
      );
    },
    moreDetails() {
      this.params.context.parentComponent.moreDetails(this.params.data.payload);
    },
    changePassword() {
      this.params.context.parentComponent.changePassword(this.params.data.id);
    },
  },
};
</script>
