<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card
          elevation="0"
          style="background-color: #f5f8fa"
          class="list-box-Shadow light_background"
        >
          <v-card-title>
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="4" class="d-flex align-center justify-start pb-4">
                <h3
                  class="text-uppercase text-h6 text_color--text font-weight-bold"
                >
                  Vendor AWB Track
                </h3>
              </v-col>
              <v-col cols="2" class="pr-2">
                <BaseSelect
                  label="Organization"
                  :itemsList="organizationList"
                  item-text="name"
                  item-value="id"
                  :isClearable="false"
                  :isDisabled="isUserTypeOrganization ? true : false"
                  v-model="selectedOrganization"
                  style="background-color: white"
                  @change="getOrganizationVendorSelectionList($event)"
                />
              </v-col>
              <v-col cols="2" class="pr-2">
                <BaseSelect
                  label="Vendor"
                  :itemsList="organizationVendorList"
                  item-text="vendor"
                  item-value="code"
                  :isClearable="false"
                  v-model="selectedVendor"
                  style="background-color: white"
                  @change="
                    searchAWBs = '';
                    clearData();
                  "
                />
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-text-field
                  hide-details
                  v-model.trim="searchAWBs"
                  outlined
                  dense
                  label="Search AWBs here.."
                  clearable
                  append-icon="mdi-magnify"
                  @keydown.enter="getVendorAwbDetailsObject()"
                  @click:append="getVendorAwbDetailsObject()"
                  @click:clear="clearData()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title v-if="searchAWBs.length == 0" style="margin-top: 250px">
            <v-row>
              <v-col cols="12">
                <span
                  class="text-h5 text_color--text font-italic d-flex align center justify-center"
                >
                  Please Search AWB and get details here...
                </span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>

        <!-- Components -->
        <div
          v-if="
            Object.values(basicDetails).length ||
            Object.values(trackingDetails).length
          "
        >
          <div v-if="basicDetails.organization_vendor_name == 'DHL'">
            <DHLAwbTrack
              :vendorDetails="basicDetails"
              :trackingDetails="
                trackingDetails.shipments ? trackingDetails.shipments[0] : {}
              "
            />
          </div>

          <div v-if="basicDetails.organization_vendor_name == 'Skip Express'">
            <SkipExpressAwbTrack
              :vendorDetails="basicDetails"
              :trackingDetailObject="trackingDetails"
            />
          </div>

          <div v-if="basicDetails.organization_vendor_name == 'SHL'">
            <ShlAwbTrack
              :vendorDetails="basicDetails"
              :trackingDetailObject="trackingDetails"
            />
          </div>

          <div v-if="basicDetails.organization_vendor_name == 'ATS'">
            <AtsAwbTrack
              :vendorDetails="basicDetails"
              :trackingDetailObject="trackingDetails"
            />
          </div>

          <div v-if="basicDetails.organization_vendor_name == 'GFS'">
            <GfsAwbTrack
              :vendorDetails="basicDetails"
              :trackingDetails="trackingDetails"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
import DHLAwbTrack from "@/pages/DHLAwbTrack";
import SkipExpressAwbTrack from "@/pages/SkipExpressAwbTrack";
import ShlAwbTrack from "@/pages/ShlAwbTrack";
import AtsAwbTrack from "@/pages/AtsAwbTrack";
import GfsAwbTrack from "@/pages/GfsAwbTrack";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "VendorDetails",
  components: {
    DHLAwbTrack,
    SkipExpressAwbTrack,
    ShlAwbTrack,
    AtsAwbTrack,
    GfsAwbTrack,
    BaseSelect,
  },
  data() {
    return {
      searchAWBs: [],
      error: null,
      basicDetails: {},
      trackingDetails: {},
      organizationList: [],
      organizationVendorList: [],
      selectedOrganization: "",
      selectedVendor: "",
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    getVendorAwbDetailsObject() {
      if (!this.selectedOrganization || !this.selectedVendor) {
        alert("Please select organization and vendor first");
        return;
      }
      bus.$emit("showLoader", true);
      let awbs = [];

      if (this.searchAWBs.length) {
        let values = this.searchAWBs.split(",");
        awbs.push(values);

        let params = {
          organization: this.selectedOrganization,
          vendor: this.selectedVendor,
          vendor_awb: awbs.join(),
        };

        this.$api.vendorAPIRequestLogs
          .getVendorAwbTrackingDetails(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            if (res.data) {
              this.basicDetails = res.data;
              this.trackingDetails = res.data.tracking_details;
            } else {
              bus.$emit("showToastMessage", {
                message: "No data found",
                color: "red",
              });
            }
            this.error = null;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.basicDetails = {};
            bus.$emit("showToastMessage", {
              message: err.data,
              color: "red",
            });
          });
      } else {
        bus.$emit("showLoader", false);
        bus.$emit("showToastMessage", {
          message: "Please enter AWB to search!",
          color: "red",
        });
      }
    },
    clearData() {
      this.searchAWBs = [];
      this.basicDetails = {};
      this.trackingDetails = {};
    },
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;

          if (this.isUserTypeOrganization) {
            let org = res.data.find((org) => {
              if (org.name == getUserProfile().organization_name) {
                return org;
              }
            });
            this.selectedOrganization = org.id;
            this.getOrganizationVendorSelectionList(this.selectedOrganization);
          }
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    // getOrganizationVendorList() {
    //   this.selectedVendor = "";
    //   this.clearData();
    //   this.$api.organizationVendor
    //     .getVendorOptions({
    //       request_type: "form",
    //       organization: this.selectedOrganization,
    //     })
    //     .then((res) => {
    //       this.vendorList = res.data.vendor;
    //     })
    //     .catch((err) => {
    //       bus.$emit("showToastMessage", {
    //         message: err.data.errors[0].error_message,
    //         color: "red",
    //       });
    //     });
    // },
    getOrganizationVendorSelectionList(organizationId) {
      let params = {
        limit: "all",
        organization: organizationId,
      };

      this.$api.organizationVendor
        .getOrganizationVendorSelectionList(params)
        .then((res) => {
          this.organizationVendorList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getOrganizationOptions();
  },
};
</script>

<style></style>
