import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.params.context.parentComponent.fromVendor)?_c(VBtn,{staticClass:"mr-1",attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.editVendorDetails()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.params.context.parentComponent.fromUser)?_c(VBtn,{staticClass:"mr-1",attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.editDetails()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.params.context.parentComponent.fromUser)?_c(VBtn,{attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.changePassword()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-key")])],1):_vm._e(),(
        _vm.params.context.parentComponent.fromReport &&
        _vm.params.data.total_records > 0
      )?_c(VBtn,{staticClass:"mr-1",attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.downloadReport()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-download")])],1):_vm._e(),(_vm.params.context.parentComponent.fromVendorShipmentDetails)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.moreDetails()}}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),(_vm.params.context.parentComponent.fromOrganizationConfiguration)?_c(VBtn,{attrs:{"color":"primary","x-small":"","depressed":""},on:{"click":function($event){return _vm.editDetails()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }