<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="clientWebhookList"
        ref="outgoingWebhookList"
        title="Client Webhook Updates"
        searchable
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        localStorageKey="outGoingWebhookColumns"
        @getList="getClientWebhookLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            fab
            color="primary"
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
          >
            <v-icon> mdi-filter</v-icon>
          </v-btn>

          <div class="ml-2">
            <FilterPanel
              filter_for="outgoing_webhook_logs"
              @applyFilters="applyFilters"
            />
          </div>
        </template>

        <template #dialogs>
          <ExportReportDialog
            title="Webhook"
            v-model="showExportDialog"
            request_type="Outgoing Webhook"
          >
          </ExportReportDialog>
          <WebhookLogsFilterDialog
            title="client_webhook_updates_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </WebhookLogsFilterDialog>
        </template>
        <template #rightSlot>
          <DateRangePicker
            style="max-width: 300px"
            id="outgoing-webhook-logs-date-range"
            v-model="dateRange"
            name="outgoing-webhook-logs-date-range"
            @update:modelValue="updateDateRange"
          />
          <!-- <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn> -->
          <!-- <v-btn
            :disabled="selectedItems.length == 0"
            small
            color="primary"
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn> -->
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasClientWebhookLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        v-model="hasClientWebhookLogsDetails"
        :details="details"
        :detailsObject="clientWebhookLogsObject"
        @closeDetails="hasClientWebhookLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import WebhookLogsFilterDialog from "@/components/common/WebhookLogsFilterDialog.vue";
import FilterPanel from "@/components/common/FilterPanel.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "ClientWebhookLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    BaseLogsDetailsLayout,
    ExportReportDialog,
    WebhookLogsFilterDialog,
    FilterPanel,
    DateRangePicker,
  },
  data() {
    return {
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasClientWebhookLogsDetails: false,
      tableData: [],
      clientWebhookLogsObject: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
    details() {
      return [
        {
          label: "Status Code",
          value: this.clientWebhookLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.clientWebhookLogsObject.organization_name,
        },
        {
          label: "Client",
          value: this.clientWebhookLogsObject.client_name,
        },
        { label: "Endpoint", value: this.clientWebhookLogsObject.endpoint },
      ];
    },
    columns() {
      const commonHeaders = [
        { headerName: "Trace ID", field: "trace_id", hide: true },
        {
          headerName: "Sent On",
          field: "created",
          minWidth: 100,
          autoHeight: true,
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Client Code",
          field: "client_code",
          minWidth: 100,
          autoHeight: true,
        },
        {
          headerName: "Client Name",
          field: "client_name",
          minWidth: 100,
          autoHeight: true,
        },
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 180,
          autoHeight: true,
        },
        { headerName: "Status", field: "status" },
        // { headerName: "Endpoint", field: "endpoint" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ];

      if (!this.isUserTypeOrganization) {
        commonHeaders.splice(3, 0, {
          headerName: "Organization",
          field: "organization_name",
        });
      }
      return commonHeaders;
    },
  },
  methods: {
    updateDateRange(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;

      let f = {};
      f.created_after = this.dateRange.start_date;
      f.created_before = this.dateRange.end_date;
      localStorage.setItem("client_webhook_updates_Filters", JSON.stringify(f));

      this.applyFilters();
    },
    getClientWebhookLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("client_webhook_updates_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.outboundLogs
        .getClientWebhookLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.outboundLogs
        .viewClientWebhookLogDetails(id)
        .then((res) => {
          this.clientWebhookLogsObject = res.data;
          this.hasClientWebhookLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.outgoingWebhookList.refreshList();
    },
  },
  created() {
    localStorage.removeItem("client_webhook_updates_Filters");
  },
};
</script>
