import { clientAWB } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getClientShipmentAWBList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(clientAWB.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method to get awb_logs
  getAwbLogsForClientShipment(awbId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${clientAWB.base}${awbId}/awb_logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewLogDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${clientAWB.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method for resend webhook update
  bulkResendWebhookUpdate(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${clientAWB.base}resend_webhook_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method For Fetching Shipment Status
  fetchUpdatesForSingleShipment(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${clientAWB.base}${id}/fetch_shipment_detail/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
