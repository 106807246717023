import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Change Password","width":"50vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showChangePasswordForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"changePasswordForm",attrs:{"id":"changePasswordForm","name":"changePasswordForm"},on:{"submit":_vm.submitForm},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Existing Password*","type":_vm.isExistingPassword ? 'text' : 'password',"append-icon":_vm.isExistingPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[(val) => !!val || 'Existing Password is required'],"error-messages":_vm.formErrors.existing_password},on:{"click:append":_vm.toggleExistingPasswordVissibility,"change":function($event){_vm.formErrors && _vm.formErrors.existing_password
                ? delete _vm.formErrors.existing_password
                : ''}},model:{value:(_vm.userProfile.existing_password),callback:function ($$v) {_vm.$set(_vm.userProfile, "existing_password", $$v)},expression:"userProfile.existing_password"}})],1),_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"New Password*","type":_vm.isNewPassword ? 'text' : 'password',"append-icon":!_vm.isNewPassword ? 'mdi-eye-off' : 'mdi-eye',"rules":[(val) => !!val || 'New Password is required'],"error-messages":_vm.formErrors.new_password},on:{"click:append":_vm.toggleNewPasswordVissibility,"change":function($event){_vm.formErrors && _vm.formErrors.new_password
                ? delete _vm.formErrors.new_password
                : ''}},model:{value:(_vm.userProfile.new_password),callback:function ($$v) {_vm.$set(_vm.userProfile, "new_password", $$v)},expression:"userProfile.new_password"}})],1),_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Confirm Password*","type":_vm.isConfirmPassword ? 'text' : 'password',"append-icon":!_vm.isConfirmPassword ? 'mdi-eye-off' : 'mdi-eye',"rules":[(val) => !!val || 'Confirm Password is required'],"error-messages":_vm.formErrors.confirm_password},on:{"click:append":_vm.toggleConfirmPasswordVissibility,"change":function($event){_vm.formErrors && _vm.formErrors.confirm_password
                ? delete _vm.formErrors.confirm_password
                : ''}},model:{value:(_vm.userProfile.confirm_password),callback:function ($$v) {_vm.$set(_vm.userProfile, "confirm_password", $$v)},expression:"userProfile.confirm_password"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"type":"submit","small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showChangePasswordForm),callback:function ($$v) {_vm.showChangePasswordForm=$$v},expression:"showChangePasswordForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }