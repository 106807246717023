<template>
  <div>
    <v-row class="ma-0">
      <v-col class="d-flex align-center">
        <v-btn
          elevation="0"
          size="large"
          icon
          color="primary"
          class="mr-2"
          name="back-button"
          @click="$router.back()"
        >
          <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <span
          class="text-h6 font-weight-bold text--primary pa-0 text-uppercase"
        >
          Vendor Shipments
        </span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        <v-btn small color="primary" @click="showResponse">
          <v-icon small class="mr-1">mdi-eye</v-icon>
          View Response
        </v-btn>

        <v-btn small color="primary" @click="showPayload" class="ml-2">
          <v-icon small class="mr-1">mdi-eye</v-icon>
          View Payload
        </v-btn>
      </v-col>      
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12" lg="3" class="py-0 pr-lg-0">
        <v-card elevation="1" rounded="lg" class="shadow-on-hover">
          <v-card rounded="lg" class="primary">
            <v-card-text class="pa-2">
              <v-row class="ma-0">
                <v-col cols="9" lg="9" class="pa-1">
                  <span class="text-caption text-white">Vendor AWB</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ vendorShipmentObject.vendor_awb ? vendorShipmentObject.vendor_awb : "-" }}
                  </span>
                </v-col>

                <v-col
                  cols="3"
                  lg="3"
                  class="pa-1 d-flex align-center justify-end"
                >
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{ vendorShipmentObject.organization_vendor_name }}
                          </span>
                        </v-chip>
                      </template>
                      Vendor
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="12 pa-1">
                  <v-divider class="white"></v-divider>
                </v-col>

                <v-col cols="8" class="pa-1">
                  <span class="text-caption text-white">Vendor AWB Status</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ vendorShipmentObject.vendor_awb_status ? vendorShipmentObject.vendor_awb_status : "-" }}
                  </span>
                </v-col>

                <v-col cols="4" class="pa-1 d-flex align-center justify-end">
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{
                              $globalConstant.formatDateValue.formatDate(
                                vendorShipmentObject.created,
                                true
                              )
                            }}
                          </span>
                        </v-chip>
                      </template>
                      Created
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>         
        </v-card>
      </v-col>

      <!-- Details -->
      <v-col cols="12" lg="9" class="pa-0 px-3">
        <v-card elevation="0" class="rounded-lg">
          <v-card-text>
            <v-row no-gutters>
                <v-col
                  cols="3"
                  v-for="(detail, key) in basicInformation"
                  :key="key"
                  class="pb-2"
                >
                <span class="text-caption text-grey">
                    {{ detail.label }}
                  </span>
                  <v-spacer />
                  <span class="text-body-2 text--primary font-weight-bold">
                    {{ detail.value ? detail.value : "-" }}                    
                  </span>
              </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>   

    <!-- Logs Section -->
    <v-row no-gutters class="pa-3">
      <!-- System Logs -->
      <v-col cols="12" lg="9">
        <v-card outlined elevation="0" class="rounded-lg">
          <v-card-title class="pa-2">
            <span class="text-body-1 font-weight-bold px-1">
              System Logs
            </span>            
          </v-card-title>
          <hr />          
          <v-card-text>
            <v-data-table              
              :headers="systemColumns"
              :items="systemLogs"
              fixed-header
              class="overflow-y-auto"
              style="height: 500px"
            >
              <template #item.created="{ item }">
                {{ formatLastUpdatedTime(item.created) }}
              </template>
              <template #item.is_success="{ item }">
                <span class="text-white text-body-2 mr-1 font-weight-bold">
                  <v-icon color="red" v-if="!item.is_success">
                    mdi-close-circle
                  </v-icon>
                  <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
                </span>
              </template>
              <template #item.actions="{ item }">
                <v-btn text @click="moreDetails(item.payload)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- History Logs -->
      <v-col cols="12" lg="3" class="pl-3">
        <v-card outlined elevation="0" class="rounded-lg">
          <v-card-title class="pa-2">
            <span class="text-body-1 font-weight-bold px-1">
              History Logs
            </span>            
          </v-card-title>
          <hr />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card
                  elevation="0"
                  class="overflow-y-auto rounded-lg"
                  style="height: 500px"
                >
                  <v-card-text v-if="!historyLogs.length">
                    <div class="py-3 d-flex justify-center">No History Found</div>
                  </v-card-text>
        
                  <v-card-text v-else class="ma-0 pa-0">
                    <v-timeline dense small>
                      <v-timeline-item v-for="item in historyLogs" :key="item.id" small>
                        <v-row no-gutters>
                          <v-col cols="12" class="primary--text">
                            {{ item.message }}
                          </v-col>
                          <v-col cols="12">
                            {{
                              item.created ? convertEventTimeStamp(item.created) : null
                            }}
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <BaseDialog
      v-model="showPayloadPreview"
      width="75vh"
      :title="title"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <JsonViewer
                  :value="payload"
                  :expanded="true"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></JsonViewer>
        <!-- <div id="pdf-content">
          <pre>{{ payload }}</pre>
        </div> -->
      </template>
      <!-- <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template> -->
    </BaseDialog>
  </div>
</template>
<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,    
    ShipmentCellRenderer,
    ActionButton,
    BaseDialog,
    JsonViewer,
  },
  data() {
    return {
      payload: {},
      showPayloadPreview: false,
      fromVendorShipmentDetails: true,
      vendorShipmentObject: {},
      trackingDetails: {},
      historyLogs: [],
      systemLogs: [],
      systemColumns: [
        {
          text: "Message",
          value: "message",          
        },
        {
          text: "Is Success",
          value: "is_success",
        },
        {
          text: "Status Code",
          value: "status_code",
        },
        {
          text: "More Details",
          value: "actions",
        },
      ],
      title: ""
    };
  },
  computed: {
    basicInformation() {
      return [
        {
          label: "Organization",
          value: this.vendorShipmentObject.organization,
        },
        {
          label: "Organization AWB",
          value: this.vendorShipmentObject.order_awb,
        },

        {
          label: "Organization AWB Status",
          value: this.vendorShipmentObject.organization_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorShipmentObject.track_no,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorShipmentObject.last_status_sent_to_org,
        },
        {
          label: "Last Update Sent To Org At",
          value: this.convertEventTimeStamp(this.vendorShipmentObject.last_update_sent_to_org_at),
        },
        {
          label: "Last Update Received At",
          value: this.convertEventTimeStamp(this.vendorShipmentObject.last_update_received_at),
        },
      ];
    },
  },

  methods: {
    showResponse() {
      this.title= "Response"
      this.payload = this.vendorShipmentObject.tracking_details;
      this.showPayloadPreview = true;
    },
    showPayload() {
      this.title= "Payload"
      this.payload = this.vendorShipmentObject.request_data;
      this.showPayloadPreview = true;
    },    
    convertEventTimeStamp(value) {
      if (value != null || value != undefined) {
        return convertDateTime(value);
      }
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },    
    viewVendorShipment() {
      let id = this.$route.query.awb
        ? this.$route.query.awb
        : this.$route.query.id;

      this.$api.vendorShipments
        .viewVendorShipment(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vendorShipmentObject = res.data;
          this.trackingDetails = res.data.tracking_details;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getVendorShipmentSystemLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    getVendorShipmentHistoryLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payload = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payload) {
        navigator.clipboard.writeText(JSON.stringify(this.payload));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    fetchVendorShipmentStatus() {
      bus.$emit("showLoader", true);

      let params = {
        vendor_awb: this.vendorShipmentObject.vendor_awb,
        organization_awb_status:
          this.vendorShipmentObject.last_status_sent_to_org,
      };

      this.$api.vendorShipments
        .fetchVendorShipmentStatus(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.viewVendorShipment();
    this.getVendorShipmentHistoryLogs();
    this.getVendorShipmentSystemLogs();
  },
};
</script>
<style>
.v-data-table-header th {
  background-color: #041065 !important; /* Green background */
  color: white !important; /* White text */
}
.ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
