<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="ordersList"
        ref="ordersList"
        title="Order Updates"
        searchable
        :table-header="columns"
        :table-data="ordersList"
        :hasAddButton="false"
        :context="context"
        :total="totalItems"
        localStorageKey="orderUpdateColumns"
        @getList="getOrderData"
      >
        <template #leftFilterSlot>
          <v-row class="pa-0 ma-0 d-flex">
            <v-col cols="4" class="pa-0 ma-0 d-flex">
              <BaseSelect
                class="rounded-pill"
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                v-model="filters.organization"
                :isDisabled="isUserTypeOrganization ? true : false"
                @change="applyFilters()"
              />
            </v-col>
            <v-col cols="4" class="pa-0 ma-0 px-2 d-flex">
              <BaseSelect
                label="Is Synced"
                class="rounded-pill"
                :isSearchRequired="false"
                :isClearable="true"
                :itemsList="syncOptions"
                item-text="label"
                item-value="value"
                v-model="filters.is_synced"
                @change="applyFilters()"
              />
            </v-col>
          </v-row>
        </template>
      </BaseListLayout>
    </v-col>

    <v-col
      cols="4"
      v-if="hasOrderDetails"
      class="pt-0 left-devider-shadow overflow-x-auto"
      style="max-height: calc(90vh)"
    >
      <OrderUpdateDetails
        :detailsObject="orderDetails"
        v-model="hasOrderDetails"
        @closeDetails="hasOrderDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import OrderUpdateDetails from "./OrderUpdateDetails.vue";
import { getUserProfile } from "@/utils/functions.js";
import { bus } from "@/main";

export default {
  name: "OrderUpdates",
  components: {
    BaseListLayout,
    BaseSelect,
    CellRenderer,
    ActionsButton,
    OrderUpdateDetails,
  },
  data() {
    return {
      totalItems: 0,
      ordersList: [],

      filters: {},
      organizationList: [],
      syncOptions: [
        { label: "Success", value: "true" },
        { label: "Failed", value: "false" },
      ],

      hasOrderDetails: false,
      orderDetails: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      if (getUserProfile()) {
        return getUserProfile().user_type == "organization";
      }
    },
    columns() {
      const commonHeaders = [
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 150,
          pinned: "left",
        },
        {
          headerName: "Account Number",
          field: "account_number",
          minWidth: 100,
          pinned: "left",
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 180,
        },
        {
          headerName: "Customer Reference Number",
          field: "customer_ref_no",
          minWidth: 150,
        },
        {
          headerName: "Vendor AWB Number",
          field: "vendor_awb_no",
          minWidth: 150,
        },
        {
          headerName: "Value",
          field: "value",
          minWidth: 100,
        },
        {
          headerName: "Origin",
          field: "origin",
          minWidth: 150,
        },
        {
          headerName: "Destination",
          field: "destination",
          minWidth: 150,
        },
        {
          headerName: "Is Synced",
          field: "is_synced",
          minWidth: 100,
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
          minWidth: 120,
          hide: true,
        },
        {
          headerName: "Modified",
          field: "modified",
          minWidth: 120,
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
          pinned: "right",
        },
      ];

      if (!this.isUserTypeOrganization) {
        commonHeaders.splice(2, 0, {
          headerName: "Organization",
          field: "organization",
          minWidth: 150,
        });
      }
      return commonHeaders;
    },
  },
  methods: {
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;

          if (this.isUserTypeOrganization) {
            let org = res.data.find((org) => {
              if (org.name == getUserProfile().organization_name) {
                return org;
              }
            });
            this.filters.organization = org.id;
            this.applyFilters();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    applyFilters() {
      localStorage.setItem(
        "order_updates_Filters",
        JSON.stringify(this.filters)
      );
      this.$refs.ordersList.refreshList();
    },

    getOrderData(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("order_updates_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }
      // if ("organization" in filters && filters != null) {
      //   filters.organization = filters.organization.join(",");
      // }

      this.$api.orderUpdates
        .getOrderUpdatesData({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.ordersList = res.data.results;
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },

    viewDetails(id) {
      this.$api.orderUpdates
        .getOrderUpdateDetails(id)
        .then((res) => {
          this.hasOrderDetails = true;
          this.orderDetails = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getOrganizationOptions();
    let f = localStorage.getItem("order_updates_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>

<style></style>
