export const version = "/api/v1/";
// export const v2 = "/api/v2/";

export const auth = {
  token: `${version}token/`,
  refresh: `${version}token/refresh/`,
};

export const dashboard = {
  base: `${version}dashboard/`,
  orgBase: `${version}org/`,
};

export const user = {
  base: `${version}user/`,
};

export const organization = {
  base: `${version}organization/`,
  optionList: `${version}organization/option_list`,
  generateApi: `${version}organization/generate_api_token`,
  config: `${version}organization-configuration/`,
};

export const client = {
  base: `${version}client/`,
};

export const organizationURL = {
  base: `${version}organization-url/`,
};

export const organizationVendor = {
  base: `${version}organization_vendor/`,
};
export const vendor = {
  base: `${version}vendors/`,
  vendor_api: `${version}vendor_api/`,
  vendor_metadata: `${version}vendor_metadata/`,
};

export const userNotification = {
  base: `${version}user-notification/`,
};

export const report = {
  base: `${version}report/`,
  // download: `${version}report/download/`,
};

export const inbound = {
  base: `${version}request_log/`,
  awb_system_log: `${version}awb_system_log/`,
  awb_history_log: `${version}awb_history_log/`,
};
export const outbound = {
  base: `${version}organization/`,
};
export const vendorShipments = {
  base: `${version}vendor_shipment_tracking_log/`,
  system_logs: `${version}vendor_awb_system_log/`,
  history_logs: `${version}vendor_awb_history_log/`,
  view_details: `${version}vendor_awb/`,
  fetchStatus: `${version}vendor_awb_track/`,
  updateStatus: `${version}vendor_status_update/`,
  fetchStatus: `${version}vendor_awb_track/`,
  resend_vendor_awb_update: `${version}resend_vendor_awb_update/`,
};

export const webhookUpdate = {
  base: `${version}awb/`,
  resend_webhook_update: `${version}awb/resend_webhook_update/`,
};

export const inboundLogs = {
  webhook: `${version}organization-webhook-log/`,
  order: `${version}client-order-log/`,
};

export const outboundLogs = {
  webhook: `${version}client-webhook-log/`,
};

export const awbTrackAndTrace = {
  base: `${version}awb-logs/`,
};

export const vendorAPIRequestLogs = {
  shipmentTracking: `${version}vendor_tracking/`,
};

export const clientApiIntegrationPartner = {
  base: `${version}client_api_integration_partner/`,
};

export const integrationPartner = {
  base: `${version}integration_partner/`,
  integrationPartnerApi: `${version}integration_partner_api/`,
};

export const vendorAwbTrack = {
  base: `${version}vendor_awb_logs/`,
};

export const clientAWB = {
  base: `${version}client_awb/`,
};

// Endpoint for Xpression Data
export const orderUpdates = {
  base: `${version}order_detail/`,
};
