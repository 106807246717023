<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="organizationWebhookList"
        ref="incomingWebhookList"
        title="Organization Webhook Updates"
        searchable
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        localStorageKey="incomingWebhookLogsColumns"
        @getList="getOrganizationWebhookLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            color="primary"
            x-small
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Webhook"
            v-model="showExportDialog"
            request_type="Incoming Webhook"
          >
          </ExportReportDialog>
          <WebhookLogsFilterDialog
            title="organization_webhook_updates_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </WebhookLogsFilterDialog>

          <BulkResendWebhookLogsDialog
            resendUpdateFor="Incoming Webhook Logs"
            v-model="showBulkResendDialog"
            @refreshList="applyFilter()"
          />
        </template>
        <template #rightSlot>
          <DateRangePicker
            style="max-width: 300px"
            id="incoming-webhook-logs-date-range"
            v-model="dateRange"
            name="incoming-webhook-logs-date-range"
            @update:modelValue="updateDateRange"
          />
          <!-- <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>
            Export Data
          </v-btn> -->
          <!-- <v-btn
            small
            color="primary"
            class="ma-1"
            @click="showBulkResendDialog = true"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn> -->
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasOrgWebhookLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="orgWebhookLogsObject.trace_id"
        v-model="hasOrgWebhookLogsDetails"
        :details="details"
        :detailsObject="orgWebhookLogsObject"
        @closeDetails="hasOrgWebhookLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import WebhookLogsFilterDialog from "@/components/common/WebhookLogsFilterDialog.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import BulkResendWebhookLogsDialog from "@/components/common/BulkResendWebhookLogsDialog.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "OrganizationWebhookLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    WebhookLogsFilterDialog,
    DateRangePicker,
    BulkResendWebhookLogsDialog,
  },
  data() {
    return {
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasOrgWebhookLogsDetails: false,
      tableData: [],
      orgWebhookLogsObject: {},

      showBulkResendDialog: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
    details() {
      return [
        {
          label: "Status Code",
          value: this.orgWebhookLogsObject.status_code,
        },
        {
          label: "Organization Name",
          value: this.orgWebhookLogsObject.organization_name,
        },
        {
          label: "Endpoint",
          value: this.orgWebhookLogsObject.endpoint,
        },
      ];
    },
    columns() {
      const commonHeaders = [
        {
          headerName: "Trace ID",
          field: "trace_id",
          hide: true,
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Received On",
          field: "created",
          minWidth: 100,
          autoHeight: true,
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 180,
          autoHeight: true,
        },
        { headerName: "Status", field: "status" },
        {
          headerName: "Client Code",
          field: "client_code",
          minWidth: 100,
          autoHeight: true,
        },
        {
          headerName: "Client Name",
          field: "client_name",
          minWidth: 100,
          autoHeight: true,
        },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ];

      if (!this.isUserTypeOrganization) {
        commonHeaders.splice(3, 0, {
          headerName: "Organization",
          field: "organization_name",
        });
      }
      return commonHeaders;
    },
  },
  methods: {
    updateDateRange(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;

      let f = {};
      f.created_after = this.dateRange.start_date;
      f.created_before = this.dateRange.end_date;

      localStorage.setItem(
        "organization_webhook_updates_Filters",
        JSON.stringify(f)
      );
      this.applyFilters();
    },
    getOrganizationWebhookLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem(
        "organization_webhook_updates_Filters"
      );
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.inboundLogs
        .getOrganizationWebhookLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.inboundLogs
        .getOrganizationWebhookLogsObject(id)
        .then((res) => {
          this.orgWebhookLogsObject = res.data;
          this.hasOrgWebhookLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.incomingWebhookList.refreshList();
    },
  },
  created() {
    localStorage.removeItem("organization_webhook_updates_Filters");
  },
};
</script>
