<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="pa-0 light_background pt-4">
        <v-row>
          <v-col cols="10" class="d-flex justify-space-between px-8">
            <div>
              <h3 class="text-uppercase text-h6 font-weight-bold">Dashboard</h3>
            </div>
          </v-col>
          <v-col cols="2" class="px-8 d-flex justify-end">
            <!-- <DateRangePicker
              id="dashboard-date-range"
              v-model="dateRange"
              name="dashboard-date-range"
              :clearable="false"
              placeholder="Date Range"
              @update:modelValue="updateValues"
            /> -->
          </v-col>
          <v-col cols="12" md="12" sm="12" class="px-8 pt-0">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="2"
                v-for="(value, key, index) in dashboardStatistics"
                :key="index"
              >
                <v-card elevation="1" class="rounded-lg secondary">
                  <v-card-title class="d-flex justify-center">
                    <h3
                      class="text--primary font-weight-bold text-body-2 text-capitalize"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </h3>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-icon style="margin-right: 10px">
                          {{ dashboardIcon[key] }}
                        </v-icon>
                        <span
                          class="text-body-1 text--primary font-weight-bold"
                          >{{ value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-text class="pa-0 light_background">
        <!-- <v-row class="pa-0 d-flex justify-end">
          <v-col col="12" lg="2" class="pr-8">
            <v-select
              hide-details="auto"
              outlined
              v-model="selected_year"
              :items="years"
              item-text="year"
              item-value="year"
              label="Year"
              dense
              @change="getOrganizationDashboardCharts()"
            />
          </v-col>
        </v-row> -->
        <v-row style="height: 550px" class="overflow-y-auto pr-4 pt-2">
          <v-col cols="12" lg="4" class="pr-2 pl-8">
            <v-card flat outlined class="rounded-lg">
              <v-card-title>
                <v-row class="d-flex justify-space-between align-center">
                  <v-col class="d-flex justify-start">
                    <span class="text-body-1 font-weight-bold">
                      Client AWB requests
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <BarChart
                  :height="250"
                  v-if="chartsData.client_awb_request_chart"
                  ref="barChart"
                  :labels="chartsData.client_awb_request_chart.labels"
                  :data="chartsData.client_awb_request_chart"
                  :options="barChartOptions"
                  :key="chartsKey"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pr-2 pl-8">
            <v-card flat outlined class="rounded-lg">
              <v-card-title>
                <v-row class="d-flex justify-space-between align-center">
                  <v-col class="d-flex justify-start">
                    <span class="text-body-1 font-weight-bold">
                      Vendor AWB requests
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <BarChart
                  :height="250"
                  v-if="chartsData.vendor_awb_request_chart"
                  :labels="chartsData.vendor_awb_request_chart.labels"
                  :data="chartsData.vendor_awb_request_chart"
                  :options="barChartOptions"
                  :key="chartsKey"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" class="pr-2 pl-8">
            <v-card flat outlined class="rounded-lg">
              <v-card-title>
                <v-row class="d-flex justify-space-between align-center">
                  <v-col class="d-flex justify-start">
                    <span class="text-body-1 font-weight-bold">
                      Webhook Updates sent
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="d-flex justify-center">
                <BarChart
                  :height="250"
                  v-if="chartsData.webhook_request_chart"
                  :labels="chartsData.webhook_request_chart.labels"
                  :data="chartsData.webhook_request_chart"
                  :options="barChartOptions"
                  :key="chartsKey"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between px-8">
            <div>
              <h3 class="font-weight-bold">Top 5 Clients</h3>
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="px-8 pt-0">
            <v-row>
              <v-col
                cols="12"
                md="2"
                sm="2"
                v-for="(value, index) in top_5_clients"
                :key="index"
              >
                <v-card elevation="1" class="rounded-lg secondary">
                  <v-card-title class="d-flex justify-center">
                    <h3
                      class="text--primary font-weight-bold text-body-2 text-capitalize"
                    >
                      {{ value[0] }}
                    </h3>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <span
                          class="text-body-1 text--primary font-weight-bold"
                        >
                          {{ value[1] }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BarChart from "@/components/BaseComponents/BarChart.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import { getUserProfile } from "@/utils/functions.js";
import { format } from "date-fns";
import { bus } from "@/main";

export default {
  name: "Dashboard",
  components: { BarChart, DateRangePicker },
  data() {
    return {
      selected_year: new Date().getFullYear(),
      years: [],
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      dashboardStatistics: {},
      top_5_clients: [],
      chartsData: {},
      dashboardIcon: {
        total_clients: "mdi-account-group",
        total_vendors: "mdi-office-building",
        webhook_enabled_clients: "mdi-account-group",
        total_vendor_awbs: "mdi-list-box-outline",
        total_client_awbs: "mdi-list-box-outline",
      },
      chartsKey: 0,
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: "100",
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
      },
    };
  },
  methods: {
    updateValues(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;
      this.getDashboardStatistics();
    },
    getDashboardStatistics() {
      let params = {};
      // if (this.dateRange) {
      //   params.start_date = format(this.dateRange.start_date, "y-MM-dd");
      //   params.end_date = format(this.dateRange.end_date, "y-MM-dd");
      // }
      params.organization = getUserProfile().organization;
      bus.$emit("showLoader", true);
      this.$api.dashboard
        .getOrganizationDashboardStatistics(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.dashboardStatistics = res.data;
          this.top_5_clients = res.data.top_5_clients;
          delete this.dashboardStatistics.top_5_clients;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
        });
    },
    getOrganizationDashboardCharts() {
      let params = {};
      // if (this.dateRange) {
      //   params.selected_year = this.selected_year;
      // }
      params.organization = getUserProfile().organization;

      bus.$emit("showLoader", true);
      this.$api.dashboard
        .getOrganizationDashboardCharts(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.chartsData = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
        });
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 10; // Start from 10 years ago
      const endYear = currentYear + 10; // End 10 years in the future
      this.years = [];
      for (let year = startYear; year <= endYear; year++) {
        this.years.push({ year });
      }
    },
  },
  mounted() {
    // this.generateYears();
    this.getDashboardStatistics();
    this.getOrganizationDashboardCharts();
  },
};
</script>